import React,{useRef} from 'react'
import { Container } from 'react-bootstrap'
import ReactHlsPlayer from 'react-hls-player';
import './live.css'
// const hlsUrl = 'http://72.204.241.47:9091/mediaserver/hls/channel7.m3u8';
const hlsUrl ='https://72.204.241.47:9091/hls/stream.m3u8'
const listen ="https://embed.wowza.com/api/video/embed.jsp?id=gjt1kgpf&pi=4278e05a-dead-48dd-a3be-9ef9cb6e9f04"

const live = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  return (

    <Container>
     <div class="py-5"></div>
  

 
     {/* <iframe src="http://72.204.241.47:9091/mediaserver/hls/stream.m3u8" frameBorder="0" width="640px" height="900px"></iframe>


    <video  preload="metadata" autoPlay={false} controls={true} webkit-playsinline="webkit-playsinline" className="videoPlayer">
   <source src='http://72.204.241.47:9091/mediaserver/hls/stream.m3u8' type="video/mp4" />
  </video> */}







<iframe src="https://player.castr.com/live_7dc04e3094ae11efb2f551bac7a58814" autoplay="0" width="100%" allowfullscreen   webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen className='video-iframe'>
<h2>testing</h2>
</iframe>











{/* 
      style={{position: 'relative', paddingBottom: '56.25%', height: 0 ,overFlow: 'hidden',maxWidth:'100%' }} */}


    <div class="py-5"></div>
    <div class="py-5"></div>
    <div class="py-5"></div>
    </Container>
  )
}

export default live
