import React, { useEffect, useState } from 'react'
import Mynav from '../../components/Header/Header'
import { Container, Col, Row } from 'react-bootstrap'
import { MemoryRouter, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios'
import moment from 'moment'
import Spinner from 'react-bootstrap/Spinner';
import './News.css'


import PaginationComponent from '../../components/Pagination/Pagination';
import SpinnerComponents from '../../components/Spinner/SpinnerComponents';
import NewsSlide from '../../components/NewsSlide/NewsSlide';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPosts } from '../../redux/actions/actions';
import { fetchNews } from '../../redux/actions/newsactions';

const News = () => {
const [post, setPost] = useState([])
let totalItems;
let totalPages;

let currentItems = [];

const [loading, setLoading] = useState(false)


const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // You can ch
  const newsinfo = useSelector(state => state.news)
const dispatch = useDispatch()


const format2 = "YYYY-MM-DD"
const regex = /(<([^>]+)>)/gi;
  useEffect(() => {








//     const myfetch =  async() => {

//     const res = await axios.get('https://mychannel7tv.co.uk/wp-json/wp/v2/posts')

//   console.log("post",res.data)

 
//   setPost(res.data)
  
// //   dispatch(fetchNews(res.data))
//   setLoading(true)
   
  
//     }

//     myfetch()

    dispatch(fetchNews())
    let {loading: isloading, news} = newsinfo

    setData(news.results)


  }, [])


  

 

  







if (newsinfo !== undefined && data !== undefined){

  totalItems = data.length;
  totalPages = Math.ceil(totalItems / itemsPerPage);
   const indexOfLastItem = currentPage * itemsPerPage;
   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
   currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
}


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };




 
  return (
    <>
    <NewsSlide/>
   <Container>
       
    
        <Row>

          
        { data  && currentItems.map(item => (

          
        <Col lg={4}   md={6} className="mr-5 ml-5" key={item.id}>

       <img src={item.Image}  className='featuredImage'/>
        <p className='h5 mt-2 news-title'>{item.category.name}</p>
   <h5 className="h6 text-truncate">{item.title}</h5>
   <p className="text-truncate">{(item.content).replace(regex, "")}</p>
       <span>{moment(item.publish_date).format(format2)}</span> || Author: <span>{item.Author}</span>
       <div className="mb-4 h6"> <Link to={`/news/${item.id}`}><h6 className='news-info text-truncate h6'>Learn More</h6></Link></div>
        
        
        
        </Col>

))}
     

     
        
      </Row>
     



  <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />


    
    </Container>

    </>
  )


  
}

export default News