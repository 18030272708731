import React from 'react';
import './DownloadPage.css'; // For styling the page
import { Container, Col, Row } from 'react-bootstrap'
import play from '../src/assets/img/slides/playstore.png'
const DownloadPage = () => {
  return (
    <Container>
    <div className="download-page">
      <h1>Download Our App</h1>
      <p>Get the best app for your device. Available on both the Google Play Store and the Apple App Store!</p>

      <div className="download-buttons">

    
        <a
          href="https://play.google.com/store/apps/details?id=com.mychannel7&hl=en_US"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={play}
            alt="Get it on Google Play"
            className="store-badge"
          />
        </a>

        <a
          href="https://apps.apple.com/us/app/channel-7/id1543510912"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
            alt="Download on the App Store"
            className="store-badge"
          />
        </a>
      </div>

      <div className="pwa-info">
        <p>If you prefer, you can also add this app to your home screen as a PWA.</p>
        <button onClick={handleAddToHomeScreen}>Add to Home Screen</button>
      </div>

   </div>
   </Container>
  );
};

// Add PWA "Add to Home Screen" prompt functionality
const handleAddToHomeScreen = () => {
  if (window.matchMedia('(display-mode: standalone)').matches) {
    alert('You have already added the app to your home screen!');
  } else {
    // Trigger prompt for "Add to Home Screen"
    if (window.deferredPrompt) {
      window.deferredPrompt.prompt();
      window.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        window.deferredPrompt = null;
      });
    }
  }
};

export default DownloadPage;
