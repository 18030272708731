import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { Col, Row } from 'react-bootstrap'
import Container from 'react-bootstrap/esm/Container';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import './Post.css'
import BackArrow from '../../components/BackArrow/BackArrow';
import PaginationComponent from '../../components/Pagination/Pagination';
const ShowPage = () => {
    let { id } = useParams();


const [news, setNews] = useState()

const [videos, setVideos] = useState([])
const [currentPage, setCurrentPage] = useState(1);
const itemsPerPage = 6; // You can ch
const navigate = useNavigate()

const goBack = () => {


 navigate('/shows')
}
   

    useEffect(() => {
        // Fetch the show details from the API using the ID
        const fetchShowDetails = async () => {
            try {
                const response = await axios.get(`https://nastaanaisie.pythonanywhere.com/api/shows/${id}/`);
                console.log("Single Show",response.data)
                setNews(response.data);
            } catch (error) {
                console.error('Error fetching the show details:', error);
            }
        };

       fetchShowDetails();



       const fetchVideos =  async() => {

        const  res = await  axios.get('https://nastaanaisie.pythonanywhere.com/api/videos/')
        console.log("videos", res.data.results)
  
        let mydata = res.data.results
  
       let infoVideo =  mydata.filter((data)=>{
  
          return data.episode.show == id
        }
  

  
        )
    

        console.log("info", infoVideo)

        setVideos(infoVideo)
        // setCategory(res.data)
        // setLoading(true)
  
  
    
      }
  
      fetchVideos()






    }, [id]);






  const totalItems = videos.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = videos.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };



  return (


    <Container>

<BackArrow buttonText="Back To Shows" handleBack={goBack}/>
<Row>

          
{ videos &&  currentItems.map(item => (

  
<Col lg={4}   md={6} className="mr-5 ml-5" key={item.id}>

{/* <img src={item.Image}  className='featuredImage'/> */}
<iframe src={item.video_url}></iframe>
        <span>{item.episode.show.title}</span>
       <h6 className='text-truncate py-2'>{item.title}</h6> 




</Col>

))}




</Row>




<PaginationComponent
currentPage={currentPage}
totalPages={totalPages}
onPageChange={handlePageChange}
/>

     
    

    </Container>
  )
}

export default ShowPage