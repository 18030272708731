import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MemoryRouter, Routes, Route, NavLink, Router, BrowserRouter } from 'react-router-dom';
import About from './screen/About/About';
import Home from './screen/Home/Home';
import Show from './screen/Show/Show';
import Contact from './screen/Contact/Contact';
import Schedule from './screen/Schedule/Schedule';
import Mynav from './components/Header/Header';
import Header from './components/Header/Header';
import { Container } from 'react-bootstrap';
import Footer from './components/Footer/Footer';
import Post from './screen/Post/Post';
import News from './screen/News/News';

import { Provider, useDispatch } from 'react-redux';
import store from './redux/store';
import { fetchShows } from './redux/actions/showsactions';
import live from './screen/live/live';
import Reviews from './screen/Reviews/Reviews';
import ShowPage from './screen/SingleShow/ShowPage';
import Movies from './screen/Movies/Movies';
import Terms from './screen/Terms/Terms';
import Privacy from './screen/Privacy/Privacy';

import * as serviceWorker from "./serviceWorker"



import DownloadPage from './DownloadPage';

// let deferredPrompt;

// window.addEventListener('beforeinstallprompt', (e) => {
//   // Prevent the default behavior of the prompt
//   e.preventDefault();
//   // Stash the event so it can be triggered later
//   deferredPrompt = e;
// });


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  
  <React.StrictMode>
    <Provider store={store}>

   
<BrowserRouter>

   {/* <Container className="main-Container mt-3"> */}
   <Header/>
<Routes>

<Route exact   path="/" Component={App}/>
<Route  exact  path="/about" Component={About}/>
<Route  exact path="/shows" Component={Show} />
<Route  exact path="/movies" Component={Movies} />
<Route exact path="/news/:id" Component={Post}/>
<Route exact path="/shows/:id" Component={ShowPage}/>
<Route exact path="/news" Component={News}/>
<Route exact path="/contact" Component={Contact} />
<Route  exact path="/schedule" Component={Schedule}/>
<Route  exact path="/terms" Component={Terms}/>
<Route  exact path="/privacy" Component={Privacy}/>
<Route  exact path="/live" Component={live}/>

<Route exact path="/download" Component={DownloadPage}/>

</Routes>


{/* </Container> */}
<Footer/>
</BrowserRouter>

</Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();