import React from 'react'
import { Container, Col,Row, ListGroup, Nav } from 'react-bootstrap'
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  'react-social-icons/facebook'
import 'react-social-icons/instagram'
import 'react-social-icons/youtube'

import { SocialIcon } from 'react-social-icons'

import play from '../../assets/img/slides/google.jpg'

import app from '../../assets/img/slides/apple.jpg'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
    <Container fluid className="footer-bg py-4 mt-3">
    
    <Row>
      <Col sm={6} lg={3}>  
    <h5 className='h5 text-center mt-2'>QUICK LINKS</h5>
    <div  className="d-flex justify-content-center align-items-center">
    <Nav defaultActiveKey="/home"  className="flex-column"  >
    <Nav.Link href="/home" class="text-white">About Us</Nav.Link>
    <Nav.Link eventKey="link-1" class="text-white">Contact Us</Nav.Link>
    <Nav.Link eventKey="link-2" class="text-white">Privacy Link</Nav.Link>
    <Nav.Link eventKey="disabled" disabled class="text-white">
      Terms
    </Nav.Link>
  </Nav>
  </div>
  </Col>
      <Col  sm={6} lg={3}>
      
    <h5 className='h5 text-center mt-4'>TV SHOWS</h5>
    <div  className="d-flex justify-content-center align-items-center">
    <Nav defaultActiveKey="/home" className="flex-column ">
    <Nav.Link href="/home" class="text-white">VOA</Nav.Link>
    <Nav.Link eventKey="link-1" class="text-white">Executive TV</Nav.Link>
    <Nav.Link eventKey="link-2" class="text-white">Movies</Nav.Link>
    <Nav.Link eventKey="disabled" disabled class="text-white">
      News
    </Nav.Link>
  </Nav>
  </div>
      </Col>
      <Col  sm={6} lg={3}>
      
    <Nav defaultActiveKey="/home" className="flex-column text-center">
    <Nav.Link href="/home" class="text-white">Sunday Worship</Nav.Link>
    <Nav.Link eventKey="link-1" class="text-white">Videos</Nav.Link>
    <Nav.Link eventKey="link-2" class="text-white">TV</Nav.Link>
    <Nav.Link eventKey="disabled" disabled class="text-white">
      TVS
    </Nav.Link>
  </Nav>
      
      </Col>
      <Col  sm={6} lg={3}>
      <h5 className='h5 text-center mt-3'>ABOUT US </h5>
      
    <p className="text-justify text-wrap">
    MyChannel7 Is the cord to link Africans and the continent’s friends and partners in the UK and Europe to the African continent.
    
    <div className="d-flex mt-2 mx-auto text-center mr-auto justify-content-center">
    <SocialIcon url="https://www.facebook.com/myChannel7tv/"  />
    <SocialIcon url="https://www.instagram.com/mych7_sky181/" />
    <SocialIcon url="https://www.youtube.com/channel/UCEoB_eWVj-X9nBrLPvvXYLQ" />
    <SocialIcon url="https://apps.apple.com/us/app/channel-7/id1543510912"/>
   
    </div>

<hr/>
    <div className='d-flex mr-1 mx-auto'>
      <Link to="https://apps.apple.com/us/app/channel-7/id1543510912">
      <img src={app} className='appimage'  />
      </Link>
   <Link to="https://play.google.com/store/apps/details?id=com.mychannel7&hl=en_US">
   <img src={play} className='appimage' />
   </Link>
    
    </div>
   
    </p>
      
      
      </Col>
    </Row>



    </Container>
    <footer className='bg-dark footer-container' fluid>        
        <Row>
        <Col sm={6} className='mt-3 mx-auto text-white'><p>2024&copy;Mychannel7tv</p></Col>
        <Col sm={6} className='mt-3 mx-auto text-white'><p>Developed By Adansonia Partners USA </p></Col>
      </Row>
        
        </footer>

        </>
  )
}

export default Footer