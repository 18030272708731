import React from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import {LinkContainer} from 'react-router-bootstrap'
import Form from 'react-bootstrap/Form';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { MemoryRouter, Routes, Route, NavLink } from 'react-router-dom';
import {Navbar, Nav} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import About from '../../screen/About/About';
import Home from '../../screen/Home/Home';
import Show from '../../screen/Show/Show';
 import channel from "../../assets/img/slides/channellogo.png"
import Contact from '../../screen/Contact/Contact';
import Schedule from '../../screen/Schedule/Schedule';

import './Header.css'
const Header = () => {


  return (

    <Navbar expand="lg" className="bg-body-teritary">
       
    <Container >
      <Navbar.Brand to="/"><img src={channel}  style={{width: '80px', height: '80px' }} className="ml-5"/></Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav
          className="me-auto my-2 my-lg-0"
          style={{ maxHeight: '100px' }}
          navbarScroll
        >
          {/* <Nav.Link to="/">Home</Nav.Link> */}
          <LinkContainer to="/">
          <Nav.Link>Home</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/live">
          <Nav.Link  className="text-primary">
            live TV
          </Nav.Link>
          </LinkContainer>
          <LinkContainer to="/schedule">
          <Nav.Link>Schedule</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/shows">
          <Nav.Link>Shows</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/movies">
          <Nav.Link>Movies</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/news">
          <Nav.Link>News</Nav.Link>
          </LinkContainer>
          <NavDropdown title="About" id="navbarScrollingDropdown" >
          <LinkContainer to="/about">
            <NavDropdown.Item>ABOUT US</NavDropdown.Item>
            </LinkContainer>

<LinkContainer to="/contact" className="text-success">
            <NavDropdown.Item >
              CONTACT US
            </NavDropdown.Item>
            </LinkContainer>


<LinkContainer to="/terms">
            <NavDropdown.Item>
              TERMS
            </NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />
            <LinkContainer to="/privacy">
            <NavDropdown.Item >
              PRIVACY
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/download">
            <NavDropdown.Item >
              Download
            </NavDropdown.Item>
            </LinkContainer>
           
          </NavDropdown>

          {/* <LinkContainer to="/news">
          <Nav.Link  className="text-primary">
            News
          </Nav.Link>
          </LinkContainer> */}

          

          {/* <LinkContainer to="/live">
          <Nav.Link  className="text-primary">
            live TV
          </Nav.Link>
          </LinkContainer> */}

         
        
         

          {/* <Link to="/live">Live</Link> */}
          {/* <Button variant="outline-primary"><Link to="/live">Live</Link></Button> */}
       
          {/* <h4 className='mt-2 text-white '><Link to="/live">WATCH</Link></h4> */}
        
          {/* <LinkContainer to="/live">
          <Nav.Link>Watch</Nav.Link>
          </LinkContainer> */}
       
        </Nav>
        {/* <Button variant="outline-primary" >Live</Button> */}
        <Form className="d-flex">
          <Form.Control
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Search"
          />
          <Button variant="outline-primary">Search</Button>
        </Form>
      </Navbar.Collapse>
    </Container>


  </Navbar>
  

  )
}

export default Header